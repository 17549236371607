import { Progress, Spin, Steps } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaUserCheck } from "react-icons/fa";
import { FaRegHourglassHalf } from "react-icons/fa6";
import { IoPerson } from "react-icons/io5";
import { MdCloudUpload, MdRateReview } from "react-icons/md";
import Select from "react-select";
import { apiService, ROUTES } from "../Services/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fade } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
// import DriverIdIcon from "../../public/assets/id-card-svgrepo-com.svg";

// export const inputTypes = {
//   TEXT: "text",
//   NUMBER: "number",
//   FILE: "file",
//   TEL: "tel",
// };

// const formMetaData = [
//   {
//     fields: [
//       {
//         label: "First Name",
//         field_key: "first_name",
//         placeholder: "Enter your first name",
//         type: inputTypes.TEXT,
//       },
//       {
//         label: "Last Name",
//         field_key: "last_name",
//         placeholder: "Enter your last name",
//         type: inputTypes.TEXT,
//       },
//       {
//         label: "Email",
//         field_key: "email",
//         placeholder: "Enter your email",
//         type: inputTypes.TEXT,
//       },
//     ],
//   },
// ];

const staticFields = [
  "first_name",
  "last_name",
  "email",
  "primary_mobile",
  "secondary_mobile",
  "address_line1",
  "address_line2",
  "vehicle_name",
  "vehicle_type",
  "vehicle_number",
  "vehicle_manufacturer",
];

function Home() {
  const [transports, setTransports] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    formState: { errors },
    getValues,
  } = useForm();
  const formValues = watch();
  const drivingLicense = useRef(null);
  const rcBook = useRef(null);
  const addressProof = useRef(null);
  const insurance = useRef(null);

  const [loadingState, setLoadingState] = useState(false);

  // const handleExternalSubmit = () => {
  //   handleSubmit(onSubmit)(); // Call handleSubmit to trigger validation and onSubmit function
  // };

  const handleDrivingLicenseClick = (event) => {
    if (drivingLicense.current) {
      drivingLicense.current.click();
    }
  };
  const handleRcBookClick = (event) => {
    rcBook.current.click();
  };
  const handleAddressProofClick = (event) => {
    addressProof.current.click();
  };
  const handleInsuranceClick = (event) => {
    insurance.current.click();
  };

  const onSubmit = async (data) => {
    setLoadingState(true);
    console.log(errors);
    console.log(data);
    var formData = new FormData();
    staticFields.map((item) => {
      if (item == "vehicle_type") {
        formData.append(item, formValues[item].value);
      } else {
        formData.append(item, formValues[item]);
      }
    });
    formData.append("driving_license", formValues.driving_license);
    formData.append("road_license", formValues.road_license);
    formData.append("zanzibar_id", formValues.zanzibar_id);
    formData.append("insurance", formValues.insurance);
    const res = await apiService.submitDriverRegistration(
      ROUTES.SUBMIT_SESSION,
      sessionId,
      formData
    );
    console.log(res);
    if (res.status === 201) {
      setIsSubmitted(true);
      toast.success("Your Registration is Successful");
    } else {
      toast.error("Failed to Register");
    }
    setLoadingState(false);
  };

  const [sessionId, setSessionId] = useState();

  const createSession = async () => {
    setLoadingState(true);
    console.log(formValues);
    if (
      formValues?.first_name === undefined ||
      formValues?.last_name === undefined ||
      formValues?.email === undefined
    ) {
      toast.error("Please enter all the fields");
    } else if (
      formValues?.first_name.length === 0 ||
      formValues?.last_name.length === 0 ||
      formValues?.email.length === 0
    ) {
      toast.error("Please enter all the fields");
    } else {
      const getAlltransports = await apiService.get(ROUTES.GET_ALL_TRANSPORTS);
      // let transports = getAlltransports.data.data;
      let transportOptions = [];
      getAlltransports.data.data.map((item) => {
        transportOptions.push({
          label: item.transport_type,
          value: item.id,
        });
      });
      setTransports(transportOptions);
      var endpoint = ROUTES.REGISTER_SESSION;
      const response = await apiService.post(endpoint, {
        contact_id: formValues?.email,
      });
      console.log(response);
      if (response.status === 201) {
        setSessionId(response.data.data);
        toast.success("OTP Sent Successfully");
      }
    }
    setLoadingState(false);
  };

  const setFile = async (filename, key, contact_id, session_id) => {
    try {
      const url = `${process.env.REACT_APP_API_PREFIX}/driver/store/get/${contact_id}/${filename}`;

      const headers = {
        "X-Session-Id": session_id,
        "Content-Type": "application/pdf", // Modify as per the file type
      };

      const response = await fetch(url, { method: "GET", headers });
      const blob = await response.blob();

      // Create a new file object from the blob
      const file = new File([blob], filename, { type: blob.type });
      setValue(key, file);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const verifySession = async () => {
    setLoadingState(true);
    if (
      formValues?.first_name === undefined ||
      formValues?.last_name === undefined ||
      formValues?.email === undefined ||
      formValues?.otp === undefined
    ) {
      toast.error("Please enter all the fields");
    } else if (
      formValues?.first_name.length === 0 ||
      formValues?.last_name.length === 0 ||
      formValues?.email.length === 0 ||
      formValues?.otp === 0
    ) {
      toast.error("Please enter all the fields");
    } else if (formValues.otp.length < 4) {
      toast.error("Please enter valid OTP");
    } else {
      var endpoint = ROUTES.VERIFY_SESSION;
      const response = await apiService.post(endpoint, {
        session_id: sessionId,
        otp: formValues.otp,
      });
      console.log(response);
      if (response.status === 200) {
        setCurrent(2);
        const isDataExists = await apiService.getDriverDetails(
          ROUTES.GET_SINGLE_DRIVER_DETAILS,
          sessionId
        );
        if (isDataExists.status === 200) {
          isDataExists.data.data.map((item) => {
            setValue(item.key, item.value);
            if (item.key === "vehicle_type") {
              transports.map((transport) => {
                if (item.value === transport.value) {
                  setValue(item.key, transport);
                }
              });
            } else if (
              item.key === "driving_license" ||
              item.key === "road_license" ||
              item.key === "zanzibar_id" ||
              item.key === "insurance"
            ) {
              setFile(item.value, item.key, formValues?.email, sessionId);
            }
          });
        }
        console.log(isDataExists.data.data);
        console.log(formValues);
        toast.success("User Verified Successfuly");
        setLoadingState(false);
      } else {
        toast.error("Invalid OTP");
        setLoadingState(false);
      }
    }
    setLoadingState(false);
  };

  // useEffect(() => {
  //   const getData = async () => {
  //     const getAlltransports = await apiService.get(ROUTES.GET_ALL_TRANSPORTS);
  //     // let transports = getAlltransports.data.data;
  //     let transportOptions = [];
  //     getAlltransports.data.data.map((item) => {
  //       transportOptions.push({
  //         label: item.transport_type,
  //         value: item.id,
  //       });
  //     });
  //     setTransports(transportOptions);
  //     const isDataExists = await apiService.getDriverDetails(
  //       ROUTES.GET_SINGLE_DRIVER_DETAILS,
  //       "ec7f3"
  //     );
  //     if (isDataExists.status === 200) {
  //       isDataExists.data.data.map((item) => {
  //         setValue(item.key, item.value);
  //         if (item.key === "vehicle_type") {
  //           transports.map((transport) => {
  //             if (item.value === transport.value) {
  //               setValue(item.key, transport);
  //             }
  //           });
  //         } else if (
  //           item.key === "driving_license" ||
  //           item.key === "road_license" ||
  //           item.key === "zanzibar_id" ||
  //           item.key === "insurance"
  //         ) {
  //           setFile(item.value, item.key, formValues?.email, sessionId);
  //         }
  //       });
  //     }
  //   };

  //   getData();
  // }, []);

  const validatePersonalDetails = async () => {
    const isValid = await trigger();
    console.log(errors);
    let fields = [
      { value: "primary_mobile", label: "Primary Mobile" },
      { value: "secondary_mobile", label: "Secondary Mobile" },
      { value: "address_line1", label: "Address Line 1" },
      { value: "address_line2", label: "Address Line 2" },
      { value: "vehicle_type", label: "Vehicle Type" },
      { value: "vehicle", label: "Vehicle" },
      { value: "vehicle_number", label: "Vehicle Number" },
      { value: "vehicle_manufacturer", label: "Manufacturer" },
    ];
    let errorFields = Object.keys(errors);
    const notifyFields = fields.filter((item) =>
      errorFields.includes(item.value)
    );
    console.log(notifyFields);
    let errorMessage = "";
    notifyFields.map((item, index) => {
      if (index === 0 || index === notifyFields.length - 1) {
        errorMessage = errorMessage + item.label;
      } else {
        errorMessage = errorMessage + item.label + ",";
      }
    });
    if (errorMessage.length > 0) {
      toast.error(errorMessage + " fields are required...");
    } else {
      setCurrent(current + 1);
    }
  };

  const validateDocuments = () => {
    if (
      formValues?.driving_license === undefined ||
      formValues?.road_license === undefined ||
      formValues?.zanzibar_id === undefined ||
      formValues?.insurance === undefined
    ) {
      toast.error("Please Upload All Documents...");
    } else {
      setCurrent(current + 1);
    }
  };

  const [current, setCurrent] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleFileChange = (file_name, e) => {
    const file = e.target.files[0];
    switch (file_name) {
      case "driving_license":
        setValue("driving_license", file);
        break;
      case "road_license":
        setValue("road_license", file);
        break;
      case "zanzibar_id":
        setValue("zanzibar_id", file);
        break;
      case "insurance":
        setValue("insurance", file);
        break;
      default:
        return null;
    }
  };
  console.log(formValues);

  return (
    <div className="w-full h-full">
      {loadingState && (
        <div className="">
          <Spin
            // spinning={isLoading}
            tip="Loading..."
            size="large"
            fullscreen
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 34,
                }}
                spin
              />
            }
            // className="spinning_indicator"
          />
        </div>
      )}
      <div className="flex flex-col h-full w-full items-center py-8">
        {/* Header */}
        <div className="w-[90%] flex flex-row justify-between items-center">
          <p className="text-3xl text-white font-[600] cursor-pointer">
            Zanzoom
          </p>
          <div className="bg-[#0475FF] px-2 py-2 rounded-[10px]">
            <p className="text-white">Contact Us</p>
          </div>
        </div>
        {/* Verfication Text */}
        {!isSubmitted && (
          <div
            className={`w-[90%] flex flex-col gap-2 justify-start items-start text-white ${
              isSubmitted ? "mt-16" : "mt-4"
            }`}
          >
            <p className="text-[2rem] font-[600]">Driver Registration</p>
            <p>Verify your identity to get started</p>
          </div>
        )}
        {/* Steps Form */}
        {!isSubmitted && (
          <div className="text-white w-[80%] h-[75%] mt-10 flex flex-row gap-4">
            <div className="flex flex-col justify-between items-end basis-[25%] h-[80%] relative z-[10]">
              <div className="w-[2px] h-[80%] absolute right-7 top-14 z-[-11] bg-[#21234A]"></div>
              <div className="basis-[20%] flex flex-row justify-between items-center h-[80%] gap-2 px-2">
                <div className="flex flex-col text-white basis-[80%]">
                  <p
                    className={`text-[1rem] ${
                      current === 1 ? "text-white" : "text-[#6D71A3]"
                    }`}
                  >
                    User Verification
                  </p>
                  <p className="text-[0.8rem] text-end">Enter User Details</p>
                </div>
                <div
                  className={`p-2 rounded-[50%] w-[40px] h-[40px] ${
                    current === 1 ? "active_icon" : "inactive_icon"
                  } flex flex-row justify-center items-center basis-[20%]`}
                >
                  <FaUserCheck fill="" fontSize={"1.5rem"} />
                </div>
              </div>
              <div className="basis-[20%] flex flex-row justify-between items-center h-[80%] gap-2 px-2">
                <div className="flex flex-col text-white">
                  <p
                    className={`text-[1rem] ${
                      current === 2 ? "text-white" : "text-[#6D71A3]"
                    }`}
                  >
                    Personal Details
                  </p>
                  <p className="text-[0.8rem] text-end">Enter User Details</p>
                </div>
                <div
                  className={`p-2 rounded-[50%] w-[40px] h-[40px] ${
                    current === 2 ? "active_icon" : "inactive_icon"
                  } flex flex-row justify-center items-center basis-[20%]`}
                >
                  <IoPerson fill="#00000" fontSize={"1.5rem"} />
                </div>
              </div>
              <div className="basis-[20%] flex flex-row justify-between items-center h-[80%] gap-2 px-2">
                <div className="flex flex-col text-white">
                  <p
                    className={`text-[1rem] ${
                      current === 3 ? "text-white" : "text-[#6D71A3]"
                    }`}
                  >
                    Upload Documents
                  </p>
                  <p className="text-[0.8rem] text-end">Browse and Upload</p>
                </div>
                <div
                  className={`p-2 rounded-[50%] w-[40px] h-[40px] ${
                    current === 3 ? "active_icon" : "inactive_icon"
                  } flex flex-row justify-center items-center basis-[20%]`}
                >
                  <MdCloudUpload fill="#00000" fontSize={"1.5rem"} />
                </div>
              </div>
              <div className="basis-[20%] flex flex-row justify-between items-center h-[80%] gap-2 px-2">
                <div className="flex flex-col text-white">
                  <p
                    className={`text-[1rem] ${
                      current === 4 ? "text-white" : "text-[#6D71A3]"
                    }`}
                  >
                    Review Details
                  </p>
                  <p className="text-[0.8rem] text-end">Review and Submit</p>
                </div>
                <div
                  className={`p-2 rounded-[50%] w-[40px] h-[40px] ${
                    current === 4 ? "active_icon" : "inactive_icon"
                  } flex flex-row justify-center items-center basis-[20%]`}
                >
                  <MdRateReview fill="#00000" fontSize={"1.5rem"} />
                </div>
              </div>
            </div>
            <div className="w-[2px] h-[80%] bg-slate-500"></div>
            <div className="flex-1">
              <form
                action=""
                onSubmit={handleSubmit(onSubmit)}
                className="h-[95%] flex flex-col "
              >
                {current === 1 && (
                  <Fade in={current === 1}>
                    <div className="">
                      <p className="text-xl">Step {current}/4</p>
                      <p>Verify User Session</p>
                      <div className="flex flex-col gap-4 justify-around items-start w-[80%] px-2 text-black mt-8">
                        <div className="flex flex-col w-full">
                          <label htmlFor="" className="text-white">
                            First Name
                          </label>
                          <input
                            type="text"
                            {...register("first_name", {
                              required: "First name is required",
                            })}
                            placeholder="Enter your first name"
                            className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                          />
                        </div>
                        <div className="flex flex-col w-full">
                          <label htmlFor="" className="text-white">
                            Last Name
                          </label>
                          <input
                            type="text"
                            {...register("last_name", {
                              required: "Last Name is required",
                            })}
                            placeholder="Enter your last name"
                            className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                          />
                        </div>
                        <div className="flex flex-row gap-6 justify-between items-end w-full">
                          <div className="flex flex-col w-full">
                            <label htmlFor="" className="text-white">
                              Email
                            </label>
                            <input
                              type="text"
                              {...register("email", {
                                required: "Email is required",
                              })}
                              placeholder="Enter your email"
                              className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                            />
                          </div>
                          <div
                            className="bg-[#0475FF] h-auto px-6 py-2 rounded-[10px] cursor-pointer"
                            onClick={createSession}
                          >
                            <p className="text-white">Verify</p>
                          </div>
                        </div>
                        <div className="flex flex-col w-full">
                          <label htmlFor="" className="text-white">
                            OTP
                          </label>
                          <input
                            type="number"
                            {...register("otp")}
                            placeholder="Enter OTP"
                            className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                          />
                        </div>
                      </div>
                    </div>
                  </Fade>
                )}
                {current === 2 && (
                  <Fade in={current === 2}>
                    <div className="h-[90%]">
                      <p className="text-xl">Step {current}/4</p>
                      <p>Personal Details</p>
                      <div className="flex flex-col h-[70%] overflow-auto gap-4 justify-around items-start w-[80%] px-2 text-black mt-8">
                        <div className="flex flex-row justify-between items-center w-full gap-2">
                          <div className="flex flex-col w-full">
                            <label htmlFor="" className="text-white">
                              Address Line 1
                            </label>
                            <input
                              type="text"
                              {...register("address_line1", {
                                required: true,
                              })}
                              placeholder="Address line 1"
                              className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                            />
                          </div>
                          <div className="flex flex-col w-full">
                            <label htmlFor="" className="text-white">
                              Address Line 2
                            </label>
                            <input
                              type="text"
                              {...register("address_line2", {
                                required: true,
                              })}
                              placeholder="Address line 2"
                              className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                            />
                          </div>
                        </div>
                        {/* <div className="flex flex-row gap-6 justify-between items-end w-full">
                          <div className="flex flex-col w-full">
                            <label htmlFor="" className="text-white">
                              City
                            </label>
                            <input
                              type="text"
                              {...register("city", {
                                required: true,
                              })}
                              placeholder="Enter your city"
                              className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                            />
                          </div>
                        </div> */}
                        <div className="flex flex-col w-full">
                          <div className="flex flex-row justify-between items-center w-full gap-2">
                            <div className="flex flex-col w-full">
                              <label htmlFor="" className="text-white">
                                Primary Mobile
                              </label>
                              <input
                                type="tel"
                                {...register("primary_mobile", {
                                  required: true,
                                })}
                                placeholder="Enter your primary mobile"
                                className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                              />
                            </div>
                            <div className="flex flex-col w-full">
                              <label htmlFor="" className="text-white">
                                Secondary Mobile
                              </label>
                              <input
                                type="tel"
                                {...register("secondary_mobile", {
                                  required: true,
                                })}
                                placeholder="Enter your secondary mobile"
                                className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-full">
                          <div className="flex flex-row justify-between items-center w-full gap-2">
                            <div className="flex flex-col w-full">
                              <label htmlFor="" className="text-white">
                                Vehicle Type
                              </label>
                              <Controller
                                name="vehicle_type"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    placeholder="Select Vehicle Type"
                                    options={transports}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: "#000129",
                                        borderColor: "#6D71A3",
                                        flexBasis: "20%",
                                        color: "#ffffff",
                                      }),
                                      option: (
                                        styles,
                                        { isDisabled, isFocused }
                                      ) => {
                                        return {
                                          ...styles,
                                          backgroundColor: isFocused
                                            ? "#ffffff"
                                            : "transparent",
                                          color: "black",
                                          cursor: isDisabled
                                            ? "not-allowed"
                                            : "default",
                                        };
                                      },
                                    }}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      // getVariants(e.value);
                                    }}
                                  />
                                )}
                              />
                              {errors.vehicle_type && (
                                <span className="text-[#DD0F0F]">
                                  This field is required
                                </span>
                              )}
                            </div>
                            <div className="flex flex-col w-full">
                              <label htmlFor="" className="text-white">
                                Vehicle
                              </label>
                              <input
                                type="text"
                                {...register("vehicle_name", {
                                  required: true,
                                })}
                                placeholder="Enter your vehicle name"
                                className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-full">
                          <div className="flex flex-row justify-between items-center w-full gap-2">
                            <div className="flex flex-col w-full">
                              <label htmlFor="" className="text-white">
                                Vehicle Number
                              </label>
                              <input
                                type="text"
                                {...register("vehicle_number", {
                                  required: true,
                                })}
                                placeholder="Enter your vehicle number"
                                className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                              />
                            </div>
                            {errors.vehicle_number && (
                              <p className="text-red-500">
                                {errors.vehicle_number.message}
                              </p>
                            )}
                            <div className="flex flex-col w-full">
                              <label htmlFor="" className="text-white">
                                Manufacturer
                              </label>
                              <input
                                type="text"
                                {...register("vehicle_manufacturer", {
                                  required: true,
                                })}
                                placeholder="Ex:- Honda , Toyota"
                                className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                              />
                            </div>
                            {errors.vehicle_number && (
                              <p className="text-red-500">
                                {errors.vehicle_number.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                )}
                {current === 3 && (
                  <Fade in={current === 3}>
                    <div className="">
                      <p className="text-xl">Step {current}/4</p>
                      <p>Upload Documents</p>
                      <div className="flex flex-col gap-4 justify-around items-start w-[80%] px-2 text-black mt-8">
                        <div className="flex flex-row justify-between items-center w-full gap-2">
                          <div className="flex flex-col w-full">
                            <button
                              className="w-full border-2 border-[#6D71A3] p-1 rounded-md"
                              onClick={handleDrivingLicenseClick}
                              type="button"
                            >
                              <div className="flex flex-row justify-start items-center gap-2">
                                <img
                                  src={"/driving_license.svg"}
                                  alt=""
                                  className="w-10 h-10"
                                />
                                <div className="flex flex-row gap-2 justify-between items-center w-full mr-8">
                                  <div className="flex flex-col justify-start items-start text-white">
                                    <p className="font-[500] text-[1rem]">
                                      Driver License
                                    </p>
                                    <p className="text-[0.8rem]">
                                      Upload Driving License
                                    </p>
                                  </div>
                                  <p className="text-white">
                                    {formValues?.driving_license?.name}
                                  </p>
                                </div>
                              </div>
                            </button>
                            <input
                              type="file"
                              ref={drivingLicense}
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handleFileChange("driving_license", e)
                              }
                              placeholder="Address line 1"
                              className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                            />
                          </div>
                        </div>
                        <div className="flex flex-row gap-6 justify-between items-end w-full">
                          <div className="flex flex-col w-full">
                            <button
                              type="button"
                              className="w-full border-2 border-[#6D71A3] p-1 rounded-md"
                              onClick={handleInsuranceClick}
                            >
                              <div className="flex flex-row justify-start items-center gap-2">
                                <img
                                  src={"/rcBook.svg"}
                                  alt=""
                                  className="w-10 h-10"
                                />
                                <div className="flex flex-row gap-2 justify-between items-center w-full mr-8">
                                  <div className="flex flex-col justify-start items-start text-white">
                                    <p className="font-[500] text-[1rem]">
                                      Insurance
                                    </p>
                                    <p className="text-[0.8rem]">
                                      Upload Insurance
                                    </p>
                                  </div>
                                  <p className="text-white">
                                    {formValues?.insurance?.name}
                                  </p>
                                </div>
                              </div>
                            </button>
                            <input
                              type="file"
                              ref={insurance}
                              onChange={(e) => handleFileChange("insurance", e)}
                              style={{ display: "none" }}
                              placeholder="Address line 1"
                              className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                            />
                          </div>
                        </div>
                        <div className="flex flex-row gap-6 justify-between items-end w-full">
                          <div className="flex flex-col w-full">
                            <button
                              type="button"
                              className="w-full border-2 border-[#6D71A3] p-1 rounded-md"
                              onClick={handleRcBookClick}
                            >
                              <div className="flex flex-row justify-start items-center gap-2">
                                <img
                                  src={"/rcBook.svg"}
                                  alt=""
                                  className="w-10 h-10"
                                />
                                <div className="flex flex-row gap-2 justify-between items-center w-full mr-8">
                                  <div className="flex flex-col justify-start items-start text-white">
                                    <p className="font-[500] text-[1rem]">
                                      Road License
                                    </p>
                                    <p className="text-[0.8rem]">
                                      Upload Road License
                                    </p>
                                  </div>
                                  <p className="text-white">
                                    {formValues?.road_license?.name}
                                  </p>
                                </div>
                              </div>
                            </button>
                            <input
                              type="file"
                              ref={rcBook}
                              onChange={(e) => handleFileChange("road_license", e)}
                              style={{ display: "none" }}
                              placeholder="Address line 1"
                              className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col w-full">
                          <div className="flex flex-col w-full">
                            <button
                              type="button"
                              className="w-full border-2 border-[#6D71A3] p-1 rounded-md"
                              onClick={handleAddressProofClick}
                            >
                              <div className="flex flex-row justify-start items-center gap-2">
                                <img
                                  src={"/addressProof.svg"}
                                  alt=""
                                  className="w-10 h-10"
                                />
                                <div className="flex flex-row gap-2 justify-between items-center w-full mr-8">
                                  <div className="flex flex-col justify-start items-start text-white">
                                    <p className="font-[500] text-[1rem]">
                                      Zanzibar ID
                                    </p>
                                    <p className="text-[0.8rem]">
                                      Upload Any Government issued card for
                                      Address Proof
                                    </p>
                                  </div>
                                  <p className="text-white">
                                    {formValues?.zanzibar_id?.name}
                                  </p>
                                </div>
                              </div>
                            </button>
                            <input
                              type="file"
                              ref={addressProof}
                              onChange={(e) =>
                                handleFileChange("zanzibar_id", e)
                              }
                              style={{ display: "none" }}
                              placeholder="Address line 1"
                              className="p-2 w-full rounded-[5px] bg-[#000229] border-[#6D71A3] border-[2px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                )}
                {current === 4 && (
                  <Fade in={current === 4}>
                    <div className="h-[90%]">
                      <p className="text-xl">Step {current}/4</p>
                      <p>Review Details</p>
                      {/* <div className="flex mt-4 gap-4 flex-col p-4  h-[60%] overflow-auto w-[80%] bg-[#121334]"> */}
                      <div className="flex flex-col h-[80%] overflow-auto gap-4 justify-around items-start w-[80%] bg-[#121334] p-4  mt-8">
                        <div className="flex flex-col justify-between items-start">
                          <p className="text-2xl">Information Review</p>
                        </div>
                        <div className="flex flex-col gap-4 w-full mt-8">
                          <div className="flex flex-row justify-between items-center">
                            <p>First Name</p>
                            <p>{formValues?.first_name}</p>
                          </div>
                          <div className="flex flex-row justify-between items-center">
                            <p>Last Name</p>
                            <p>{formValues?.last_name}</p>
                          </div>
                          <div className="flex flex-row justify-between items-center">
                            <p>Email</p>
                            <p>{formValues?.email}</p>
                          </div>
                          <div className="flex flex-row justify-between items-center">
                            <p>Primary Mobile</p>
                            <p>{formValues?.primary_mobile}</p>
                          </div>
                          <div className="flex flex-row justify-between items-center">
                            <p>Secondary Mobile</p>
                            <p>{formValues?.secondary_mobile}</p>
                          </div>
                          <div className="flex flex-row justify-between items-center">
                            <p>Address</p>
                            <p>
                              {formValues?.address_line1 +
                                formValues?.address_line2}
                            </p>
                          </div>
                          <div className="flex flex-row justify-between items-center w-full">
                            <p>Vehicle Type</p>
                            <p>{formValues?.vehicle_type?.label}</p>
                          </div>
                          <div className="flex flex-row justify-between items-center w-full">
                            <p>Vehicle Manufacturer</p>
                            <p>{formValues?.vehicle_manufacturer}</p>
                          </div>
                          <div className="flex flex-row justify-between items-center w-full">
                            <p>Vehicle Number</p>
                            <p>{formValues?.vehicle_number}</p>
                          </div>
                          <div className="flex flex-row justify-between items-center w-full">
                            <p>License</p>
                            <p>{formValues?.driving_license?.name}</p>
                          </div>
                          <div className="flex flex-row justify-between items-center w-full">
                            <p>Road License</p>
                            <p>{formValues?.road_license?.name}</p>
                          </div>
                          <div className="flex flex-row justify-between items-center w-full">
                            <p>Zanzibar ID</p>
                            <p>{formValues?.zanzibar_id?.name}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                )}
                <div className="flex flex-1 flex-row w-[80%] justify-end items-end gap-4 mt-4">
                  {current > 1 && (
                    <div
                      className="h-auto px-6 py-2 rounded-[10px] cursor-pointer"
                      onClick={() => setCurrent(current - 1)}
                    >
                      <p className="text-white">Back</p>
                    </div>
                  )}
                  {current < 5 && current !== 4 && (
                    <div
                      className="bg-[#0475FF] h-auto px-6 py-2 rounded-[10px] cursor-pointer"
                      onClick={() => {
                        if (current === 1) {
                          verifySession();
                        } else if (current === 2) {
                          validatePersonalDetails();
                        } else if (current === 3) {
                          validateDocuments();
                        } else {
                          setCurrent(current + 1);
                        }
                      }}
                    >
                      <p className="text-white">Next</p>
                    </div>
                  )}
                  {current < 5 && current === 4 && (
                    <div
                      className="bg-[#0475FF] h-auto px-6 py-2 rounded-[10px] cursor-pointer"
                      // onClick={handleExternalSubmit}
                    >
                      <input type="submit" value="Submit" />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
        {isSubmitted && (
          <div className="w-[60%] h-[100%] flex flex-col justify-center gap-4 items-center">
            <div className="w-72 h-72 flex flex-row justify-center rounded-[50%] items-center active_icon">
              <FaRegHourglassHalf fontSize={"10rem"} fill="" />
            </div>
            <div className="text-3xl text-white font-[600] text-center flex flex-col gap-2">
              <p>Verification Pending</p>
              <p className="text-sm font-[400]">
                Thank you for registering. Please allow 24 to 48 hours for us to
                process your submission. If you do not receive any updates
                within 48 hours, kindly reach out to us for further assistance.
              </p>
            </div>
          </div>
        )}
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}

export default Home;
