import React from "react";
import { useNavigate } from "react-router-dom";
import QrImage from "../";
import { FaArrowRight } from "react-icons/fa";
import bgVideo from '../Assets/sunsetzanzibar1.mp4'

function LandingPage() {
  const navigate = useNavigate();
  return (
    <div className="w-full h-auto flex flex-col justify-start items-center  py-4 bg-[#000029]">
      <div className="h-[100vh] w-full flex flex-col justify-start items-center backgroundimg">
        <div className="w-[90%] h-[5vh] flex flex-row justify-between items-center">
          <p className="text-3xl text-white font-[600] cursor-pointer">
            ZanZoom
          </p>
          <div className="bg-[#0475FF] px-2 py-2 rounded-[10px]">
            <p className="text-white">Contact Us</p>
          </div>
        </div>
        <div className="flex-1 flex h-full flex-col justify-center items-center text-white mt-0">
          <div className="">
            <video
              src={bgVideo}
              loop
              autoPlay
              muted
              className="w-[100vw] h-[90vh] object-cover"
            >
              {" "}
              <source src={bgVideo} type="video/mp4" />{" "}
            </video>
          </div>
          <div className="absolute text-center">
            <p className="text-[4rem] font-[600] leading-snug text-[#ffffff]">
              Welcome to ZanZoom
            </p>
            <p className="text-[1.5rem]">Join with Us</p>
            <p className="text-[1.5rem]">
              Your Ride, Your Way - Anytime, Anywhere in Zanzibar
            </p>
            <button
              className="text-white  text-[1.15rem] font-[600] bg-[#0475FF] px-8 py-4 rounded-[10px] mt-8"
              onClick={() => navigate("/register")}
            >
              Register Today
            </button>
          </div>
        </div>
      </div>
      <div className="h-[100vh] w-full flex flex-row justify-between items-center px-4 py-4 gap-8">
        <div className="w-[35vw] h-full flex flex-col justify-around x items-center rounded-[10px] ml-12">
          <div className="flex flex-col justify-between items-center gap-4 w-full">
            <div className="">
              <p className="text-[2.5rem] text-white text-center">
                Download <span className="text-[#0475FF]">Zanzoom</span> Driver App
              </p>
              {/* <div className="flex flex-col gap-4 justify-start items-start">
                {[
                  "Highest Earning",
                  "Flexible Working Hours",
                  "Direct Payments",
                  "Support and Training",
                  "Increased Ride Demand",
                ].map((item) => {
                  return (
                    <div className="flex flex-row text-white justify-start items-center">
                      <FaArrowRight fill="#0475FF" fontSize={"2rem"} />
                      <p className="text-[1.25rem]">{item}</p>
                    </div>
                  );
                })}
              </div> */}
              <p className="text-white text-center">
                Highest Earning, Flexible Working Hours, Direct Payments,
                Support and Training, Increased Ride Demand,
              </p>
            </div>
            <div className="flex flex-col items-center justify-end">
              <img src="Playstore Link.png" alt="" className="w-[15vw]" />
              <p className="text-white text-md mt-4 text-[1.2rem]">
                Scan to Download{" "}
                <span className="text-[#0475FF]">Zanzoom Driver App</span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center gap-4">
          <div className="flex flex-row gap-2 ">
            <img
              src="/image1.jpg"
              alt=""
              className="w-[15vw] h-[60vh] rounded-md mt-20"
            />
            <img
              src="/image2.jpg"
              alt=""
              className="w-[15vw] h-[60vh] rounded-md mb-20"
            />
            <img
              src="/image3.png"
              alt=""
              className="w-[15vw] h-[60vh] rounded-md mt-20"
            />
          </div>
        </div>
      </div>
      <div className="h-[5vh] flex flex-row justify-center items-end border-t-2 border-white w-full">
        <p className="text-white">Copyright © 2024 IITM All rights reserved</p>
      </div>
    </div>
  );
}

export default LandingPage;
