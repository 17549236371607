import axios from "axios";

export const ROUTES = {
  REGISTER_SESSION: "/driver/registration/session",
  VERIFY_SESSION: "/driver/registration/session/verify",
  SUBMIT_SESSION: "/driver/registration/submit",
  GET_SINGLE_DRIVER_DETAILS: "/driver/registration",
  GET_ALL_TRANSPORTS: "/driver/alltransports",
};

class ApiService {
  constructor() {
    if (new.target === ApiService) {
      throw new Error("Cannot instantiate abstract class");
    }
  }
  async isLoggedIn() {}

  async login() {}

  async get() {}

  async post() {}

  async patch() {}
}

class Dev extends ApiService {
  #host;
  #tokenType;
  constructor() {
    super();
    this.#host = "http://127.0.0.1:8000/api/v1";
  }
}

class Production extends ApiService {
  #host;
  #tokenType;
  constructor() {
    super();
    this.#host = "https://api.dev.zanzoom.com/api/v1";
  }

  async get(endpoint, payload) {
    try {
      const res = await axios.get(`${this.#host}${endpoint}`, {
        params: payload ?? {},
        // headers: this.#getHeaders(),
      });
      return res;
    } catch (e) {
      console.error(e);
      return e.response;
    }
  }

  async getDriverDetails(endpoint, session_id) {
    try {
      const res = await axios.get(`${this.#host}${endpoint}`, {
        headers: {
          "X-Session-Id": session_id,
        },
      });
      return res;
    } catch (e) {
      console.error(e);
      return e.response;
    }
  }

  async post(endpoint, payload) {
    console.log(endpoint);
    try {
      const res = await axios.post(`${this.#host}${endpoint}`, payload, {
        // params: payload ?? {},
        // headers: this.#getHeaders(),
      });
      return res;
    } catch (e) {
      return e.response;
    }
  }

  async submitDriverRegistration(endpoint, sessionId, formData) {
    try {
      const res = axios.post(`${this.#host}${endpoint}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Session-Id": sessionId,
        },
      });

      return res;
    } catch (e) {
      return e.response;
    }
  }

  async patch(endpoint, payload) {
    try {
      const res = await axios.patch(`${this.#host}${endpoint}`, payload, {
        // params: payload ?? {},
        // headers: this.#getHeaders(),
      });
      return res;
    } catch (e) {
      return e;
      //    console.error(e);
    }
  }
}

export const apiService = new Production();
